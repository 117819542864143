.addmerchant {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;

  }

  /* red text for merchantNameNotAvailable */
  .merchantNameNotAvailable {
    color: red;
    /* add some space on the left side */
    padding-left: 10px;
  }

  /* green text for merchantNameAvailable */
  .merchantNameAvailable {
    color: green;
    /* add some space on the left side */
    padding-left: 10px;
  }

  /* center go-to-merchant-page class */
  .go-to-merchant-page, .print-qr {
    text-align: center;
  }

  /* center qr code class */
  .qr-code {
    text-align: center;
  }

  .header, .footer {
    text-align: center;
    padding: 10px;
  }
  
  .outer-addmerchant-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .merchantSignupLabels {
    /* align all the content to the left */
    text-align: left;
    align-items: left;
    /* add some space between the label and the input */
    margin-bottom: 5px;

    /* add some space on the left side */
    padding-left: 10px;
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }