.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }