/* src/components/pages/Hoover/hoover.css */

.outer-home-container {
  padding: 20px;
}

.breadcrumbDiv {
  margin-bottom: 20px;
}

.home h2 {
  font-weight: bold;
}

.home ul {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .home h2 {
    font-size: 1.75rem;
  }

  .home h4 {
    font-size: 1.25rem;
  }
}
