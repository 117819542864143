.Merchant {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.instructions {
  padding: 10px;
  /* justify to the left side */
  justify-content: left;

  /* align items left */
  align-items: left;
  text-align: left;
  
}
.instructions p {
  /* add some space between the paragraphs */
  margin-bottom: 10px;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}