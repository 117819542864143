.Merchant {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}

.qr-code {
  text-align: center;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}